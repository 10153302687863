/*
 * 업무구분: 프리미엄 고객사랑 서비스
 * 화 면 명: MSPCM306P
 * 화면설명: 선물신청
 * 접근권한: FC
 * 작 성 일: 2022.07.01
 * 작 성 자: 손평국
 */

<template>
   <ur-page-container class="msp" :show-title="true" title="선물신청" type="popup" :topButton="true">
     <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
        <div class="ns-sliding-topbar">
          <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
                <!-- 헤더 타이틀 영역 -->
           <!-- <template #fixed="{scrollRate}">
            <mo-top-bar color="primary"  > -->
              <!-- <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div> -->
              <!-- <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '선물신청' : '선물신청'}}</div> -->
            <!-- </mo-top-bar>
          </template> -->

           <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>
          <!-- stickey 영역 -->     

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <template #scroll>
           <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-segment pal2430 pt15 pb90">
              <!-- 타겟고객 -->
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
                <div class="sim-customer-info">
                  <span class="fwb fs16rem">{{custInfo.custNm}}</span>
                  <span class="crTy-blue4 fwb fs16rem f-end">{{custInfo.custSrvcTypCdNm}}</span>
                </div>
                <div class="ns-pss-name" v-if="isbknocustSrvcAplNo">
                  <span class="name">이미 서비스를 신청한 고객입니다</span>
                </div>
              </ur-box-container>
              <!-- //타겟고객 -->
               <!-- 상세정보 -->
               <ur-box-container alignV="start" v-if="['01', '04'].indexOf(custInfo.custSrvcTypCd) > -1" componentid="ur_box_container_002"  direction="column" class="msp-pi-wrap mt20">
                 <mo-list :list-data="[{}]">
                   <template #list-item="{}">
                    <mo-list-item expandable :prevent-expand="index < 1" ref="listItems1" class="sub-acodian">
                      <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <div class="title">
                          <span class="title-text ml6">상세정보</span>
                        </div>
                      </div>
                    </div>
                    <template v-slot:expand class="ns-gray-box">
                      <div class="list-item__contents">
                      <div class="con-box">
                        <div class="info-text">
                          <!-- <span class="ml0">본 고객님은 이달의 타겟 고객으로 선정된 고객입니다. 고객님의 보장을 꼼꼼히 챙길 수 있도록 안내해 주시기 바랍니다.</span>  -->
                          <span class="ml0" v-html="custSrvcTyp.cMsg1"></span>
                          <span class="ml0 block" v-if="'04'.indexOf(custInfo.custSrvcTypCd) > -1">추천화법</span>
                          <span class="ml0" v-html="custSrvcTyp.cMsg2"></span>
                          <span class="ml0" v-html="custSrvcTyp.cMsg3"></span>
                        </div>
                      </div>
                    </div>
                    </template>
                    </mo-list-item> 
                   </template>
                 </mo-list>
               </ur-box-container>
               <!-- //상세정보 -->
               <!-- 상품목록 -->
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
                <div class="msp-image__lists g-row-2 mt10">
                      
                    <li v-for="(item, idx) in giftList" :key="idx">
                      <mo-list-item :class="{'on' : `Radio`+index == radio}">
                         <div class="image-list-area">
                          <img class="image-list-bg" :src="item.imgUrl" >
                            <div class="image-list-info">
                            <div class="ns-radio-list mr10 mt5"><mo-radio v-model="radioSelected" :value="item.gftNoCd" class="bgwhite" @input="fn_ItemClick(item)"/></div>
                             <span class="flex-1">
                              {{item.gftCntnt}}
                            </span> 
                           </div>
                         </div>
                      </mo-list-item>
                    </li>
                     
                </div>  

                  <ul class="terms-list-area crTy-bk7 fs14rem mt30">
                  <li class="crTy-bk7">
                    방문전달을 위해 삼성생명 및 업무위탁사에서 필요로 하는 FC성명, FC코드, 지점주소, 연락처 정보를 제공· 활용하는 것에 대하여 동의합니다.
                  </li>
                  <li class="crTy-bk7">선물 신청 시 수수료 공제가 있습니다.</li>
                </ul>
              </ur-box-container>
           </ur-box-container>
          </template>
          </mo-collapsing-header-view>
        </div>

      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click='fn_OnNegative'>취소</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_OnPositive" :disabled="radioSelected === ''">신청</mo-button>
        </div><!-- :disabled="!$bizUtil.isEmpty(radioSelected.key)" -->
      </ur-box-container> 
     </ur-box-container>
   </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPGC102P",
    screenId: "MSPGC102P",
    props: {
      'showTSSCM306P': Boolean,
      'selectItems': [Array, Object],
      'selectedCustInfo': [Array, Object]
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
   

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 지점장 여부
        isMngr: false,
        // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        authCd: 'U000',
        custInfo: [],
        giftList: [],
        radioSelected: '',
        mokupdata: [{'ddd': 'ddd'}],
        selectedItem: {},
        CMCustSrvcObjInfoVOList: [],
        custSrvcTyp:
        {
          cMsg1: '',
          cMsg2: '',
          cMsg3: ''
        },        
        // 기신청 여부
        isbknocustSrvcAplNo: false
      };
    },


    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
     
    /** computed 함수 정의 영역 */
    computed: {
    
    },
    /** watch 정의 영역 */
    watch: {
      giftList : {
        deep:true,
        handler(newVal, oldVal) {
          setTimeout(() => {
            console.log('watch giftList', newVal);
            this.$forceUpdate();
          }, 100)
          
        }
      }
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      var lv_vm = this
      var lv_today = new Date().toISOString().substring(0, 10)
      lv_today = lv_today.toString().replace(/-/gi, '')
      
      // 정보 세팅
      lv_vm.custInfo = lv_vm.$props.selectedCustInfo
      lv_vm.CMCustSrvcObjInfoVOList.push({'title': '화법'})

      lv_vm.selectedItem = !_.isEmpty(lv_vm.selectItems) ? lv_vm.selectItems[0] : {}

      // 기신청한 상품이 있을 경우 선택된 상태로 display
      if (lv_vm.custInfo.custSrvcAplYmd.length > 0) {
        if (lv_today === lv_vm.custInfo.custSrvcAplYmd) {
          lv_vm.radioSelected = lv_vm.custInfo.gftNoCd
        }
      }
      if (['01'].indexOf(lv_vm.custInfo.custSrvcTypCd) > -1) {
          lv_vm.custSrvcTyp.cMsg1 = `본 고객은 이달의  타겟 고객으로 선정된 고객입니다.
                                     고객님의 보장을 꼼꼼히 챙길 수 있도록 안내해 주시기 바랍니다.`.replace(/(?:\r\n|\r|\n)/g, '<br/>')
          lv_vm.custSrvcTyp.cMsg2 = lv_vm.selectItems[0].mainCovrCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
          lv_vm.custSrvcTyp.cMsg3 = lv_vm.selectItems[0].prpslPrdtCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')  
          
      } else if (['04'].indexOf(lv_vm.custInfo.custSrvcTypCd) > -1) {
          lv_vm.custSrvcTyp.cMsg1 = `'생일 고객'은 이번 달에 주민등록상 생일을 맞이한 고객입니다.
                                      생일고객님께 감사 인사와 선물을 전해 보세요 
                                      (*실제 생일과 다를 수 있음, 개인정보보호 이슈로 생일 저장, 활용 불가)`.replace(/(?:\r\n|\r|\n)/g, '<br/>')
          lv_vm.custSrvcTyp.cMsg2 = `안녕하세요 고객님. 오늘 우연히 고객 리스트에서 이번 달이 고객님 생일인 것을 보고 축하 선물도 보내고 인사도 드릴 겸 이렇게 연락 드렸습니다.
                                     진심으로 축하 드립니다. 그 동안 궁금하시거나 건강자산 관련 상의할 일은 없으셨나요?
                                     말씀 주시면 성심껏 확인하여 답변 드리도록 하겠습니다.`.replace(/(?:\r\n|\r|\n)/g, '<br/>')
      }
      this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
        if (['01', '04'].indexOf(lv_vm.custInfo.custSrvcTypCd) > -1) {
          this.$refs['listItems1'].expand() // 아코디언 펼침 처리
        }
      })      
      // 선물 리스트 조회
      lv_vm.fn_SelGiftList()
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************
      * Function명  : fn_DrawCanvasImg
      * 설명        : 캔버스 이미지 (사용안함 / 필요시 사용)
      ******************************************************/
      fn_DrawCanvasImg (param) {
        
        for (let i = 0; i < param.length; i++) {
          let canvasRef = this.$refs.imageCanvas
          let imageData = param[i].imgUrl
          let img = new Image()
          img.onload = function () {
            let canvas = canvasRef[i]
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          }
          img.src = imageData
        }
        
      },

      /*******************************************************
       * Function명  : fn_ItemClick
       * 설명        : 아이템 클릭
       ******************************************************/
      fn_ItemClick (item) {
        this.selectedItem = item
        this.radioSelected = ''
        this.radioSelected = item.gftNoCd
      },

      /*******************************************************
      * Function명  : fn_OnPositive
      * 설명        : 신청 버튼 클릭 시, 콜백 함수
      ******************************************************/
      fn_OnPositive () {
        var lv_vm = this
        if (lv_vm.isbknocustSrvcAplNo) {
          lv_vm.$addSnackbar('이미 서비스를 신청한 고객입니다.')
          return
        }
        lv_vm.fn_SelListMyCustAddInfo()
      },

      /*******************************************************
       * Function명  : fn_OnNegative
       * 설명        : 아니오 버튼 클릭 시, 콜백 함수
       ******************************************************/
      fn_OnNegative () {
        console.log('취소클릭')
        this.$emit('onNegative')
      },

      /*****************************************
       * Function명  : fn_Back
       * 설명        : 뒤로가기 (history back)
       *****************************************/
      fn_Back () {
        this.$emit('onNegative')
      },

      /*******************************************************
      * Function명  : fn_SelGiftList
      * 설명        : 고객사랑서비스 이미지 조회
      *               IF-CM-3048 txTSSCM51S8
      * param       : N/A
      * return      : Boolean
      ******************************************************/
      fn_SelGiftList: async function () {
        let lv_vm = this
        let trnstId = 'txTSSCM51S8'
        let pParam = {
          objYm: lv_vm.custInfo.objYm,
          cnsltNo: lv_vm.custInfo.cnsltNo,
          custId: lv_vm.custInfo.custId,
          custSrvcAplNo: lv_vm.custInfo.custSrvcAplNo,
          custSrvcTypCd: lv_vm.custInfo.custSrvcTypCd,
        }

        lv_vm.post(lv_vm, pParam, trnstId, 'S')
          .then(function (response) {

            if (response.body !== null && response.body.iCCustGftInfoVO !== undefined && response.body.iCCustGftInfoVO !== null) {
              lv_vm.giftList = response.body.iCCustGftInfoVO
            }else{
              lv_vm.$addSnackbar('상품을 조회하지 못했습니다.')
            }
          })
      },


      /*******************************************************
      * Function명  : fn_SelListMyCustAddInfo
      * 설명        : 고객사랑서비스 '내고객추가' 유형 신청 가능 여부 조회
      *               IF-CM-3048 txTSSCM51S3
      * param       : N/A
      * return      : Boolean
      ******************************************************/
      fn_SelListMyCustAddInfo: async function () {
        let lv_vm = this
        let trnstId = 'txTSSCM51S3'
        let pParam = {
          objYm: lv_vm.custInfo.objYm,
          cnsltNo: lv_vm.custInfo.cnsltNo,
          custSrvcAplNo: lv_vm.custInfo.custSrvcAplNo,
          custSrvcTypCd: lv_vm.custInfo.custSrvcTypCd
        }

        lv_vm.post(lv_vm, pParam, trnstId, 'S')
        .then(function (response) {
          if (lv_vm.DEBUG) console.log(response)
          if (response.msgComm.msgCd === 'ECIU044') {
            if (response.body.custAddmPsbYn === 'N') {
              lv_vm.$addSnackbar('내고객추가 신청이  불가능 합니다.')
              lv_vm.fn_OnNegative()
            }else{
              // lv_vm.custInfo.gftNoCd = lv_vm.selectedItem.cdVal
              lv_vm.custInfo.gftNoCd = lv_vm.selectedItem.gftNoCd
              lv_vm.custInfo.gftCntnt = lv_vm.selectedItem.gftCntnt
              lv_vm.$emit('onPositive', lv_vm.custInfo)
            }
          }
        })
      },
    }
  };
</script>
<style scoped>
  .info-text span {text-indent: 0 !important;}
  .info-text .block {display: block !important;}
</style>