/*
 * 업무구분: 프리미엄 고객사랑 서비스
 * 화 면 명: MSPCM305P
 * 화면설명: 급여공제 동의
 * 접근권한: FC
 * 작 성 일: 2022.06.29
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container :show-title="true" title="급여공제 동의" type="popup">
    <ur-box-container direction="column" alignV="start">
      <div class="ns-insideWidth">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-agree-area">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-salary-box">
            <span class="txtGray">대상년월 : {{year}}년 {{month}}월
              <br><br>
              <!-- 프리미엄고객사랑서비스 선물신청에 따른 수수료 공제를 동의합니다.<br>
              ※ 회사제공고객 10명 초과 신청분은 본인부담입니다.<br>
              ◈10명 초과시 부담금액 : 일반물품 6천원/고급물품 8천원◈ -->
              <span v-html="sarySbtrMsgCntnt"></span>
              <br><br>
            </span>
            <span class="txtGray">공제대상 고객유형</span>
            <ul class="salary-ul">
              <li v-for="(item, index) in targetContents" :key="index">
                <span>{{item.custScVal}}</span><span>{{$commonUtil.numberWithCommas(item.sarySbtrAmtVal)}}원</span>
              </li>
            </ul>
            <span class="txtGray">동의하시면 [동의] 버튼을, 동의하지 않으시면 [취소] 버튼을 눌러주세요.</span>
          </ur-box-container>
        </ur-box-container>
      </div>
      <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="onNegative()">취소</mo-button>
          <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="onPositive()">동의</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM305P",
    screenId: "MSPCM305P",
    props: {
      'pIsShowMSPCM305P': Boolean,
      'pIsSearch': {
        type: Boolean,
        default () { return false }
      },
      // 부모 페이지명
      pPage: String,
      // 대상년월
      targetDate: String,
      // 리스트
      targetContents: [],
      // 컨설턴트명
      cnsltName: String,
      // 코멘트
      targetSarySbtrMsgCntnt: String
    },
    components: {
      
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isMngr: false, // 지점장 여부
        authCd: 'U000', // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        isScrolled: false,
        lv_userInfo: {}, // 사용자정보
        lv_isShowTSSGC102P: false, // 부모창에서 전달받은 노출여부 파라미터(pr_IsShowTSSSA012P)를 저장
        year: '',
        month: '',
        contents: [],
        cnsltNm: '',
        sarySbtrMsgCntnt: ''
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      this.cnsltNm = this.$props.cnsltName
      this.year = this.$props.targetDate.substring(0,4)
      this.month = this.$props.targetDate.substring(4,6)
      this.contents = this.$props.targetContents
      this.sarySbtrMsgCntnt = this.$props.targetSarySbtrMsgCntnt
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      /*******************************************************
      * Function명  : fn_getDate(2022년 05월)
      * 설명        : fdpConfirm 예 버튼 클릭 시, 콜백 함수
      ******************************************************/
      onPofn_getDate () {
        this.year = this.$props.targetDate.substring(0,4)
        this.month = this.$props.targetDate.substring(4,6)
      },

      /*******************************************************
      * Function명  : onPositive
      * 설명        : fdpConfirm 예 버튼 클릭 시, 콜백 함수
      ******************************************************/
      onPositive () {
        this.$emit('onPositive')
      },

      /*******************************************************
      * Function명  : onPositive
      * 설명        : fdpConfirm 아니오 버튼 클릭 시, 콜백 함수
      ******************************************************/
      onNegative () {
        this.$emit('onNegative')
      },

      /*****************************************
       * Function명  : fn_Back
       * 설명        : 뒤로가기 (history back)
       *****************************************/
      fn_Back () {
        this.$emit('onNegative')
      }
    }
  };
</script>
<style scoped>
</style>